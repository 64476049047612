import React from "react";
import "./Detail.css";

const Detail = () => {
  return (
    <div className="Detail_full_container">
        <div className="image_detail"><img src="https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Free-Mockup-Scene.png"/></div>
      <div className="det_Detail_contianer">
        <div className="detail_inside">
          <div className="detail_para">Asus Zenbook Duo</div>
          <div className="det_heading">
            The Laptop <br /> Of Tomorrow
          </div>
          <div className="det_button">
            <button>Shop Pre order</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
