import logo from './logo.svg';
import './App.css';
import Header from './Component/Header/Header';
import Navbar from './Component/Navbar/Navbar';
import Box from './Component/Box/Box';
import BelowBox from './Component/Below-box/Belowbox';
import Productbox from './Component/Productbxo/Productbox';
import Detail from './Component/Detail_show/Detail';
import Two_box from './Component/Two_box/Two_box';
import Blog from './Component/Blog/Blog';
import Footer_container from './Component/Footer_container/Footer_container';
import Footer2 from './Component/Footer2/Footer2';

function App() {
  return (
    <div className='body'>
      <Header/>
      <Navbar/>
      <Box/>
     
     <Productbox/>
     <Detail/>
     <Two_box/>
     <Blog/>
    <Footer_container/> 
     <Footer2/>
    </div>
  );
}

export default App;
