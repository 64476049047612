import React from "react";
import "./Box.css";
export default function Box() {
  return (
    <div className="full_box">
      <div className="leftbox">
        <p className="box-ptag-left">Blast Past Fast</p>
        <h1 className="heading-tag-left">
          IPhone 12 Pro <br /> It’s A Leap <br /> Year.
        </h1>
        <div className="button_box-left">
          <button>shop by catagroy</button>
        </div>
        <div className="image_box_left">
          <img src="	https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Mask-Group-21.png" />
        </div>
      </div>
      <div className="midbox">
      <div className="image_box_mid">
          <img
            src="	https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Mask-Group-1.png
"
          />
        </div>
        <div className="mid-det-baqi">
        <p className="box-ptag-mid">Blast Past Fast</p>
        <h1 className="heading-tag-mid">
          IPhone 12 Pro <br /> It’s A Leap <br /> Year.
        </h1>
        <div className="button_box-mid">
          <button>shop by catagroy</button>
        </div>
        </div>
      </div>
      <div className="rightbox">
        <p className="box-ptag-right">Macbook Pro</p>
        <h1 className="box-heading-right">
          More Power. <br /> Wakes <br /> Instantly.
        </h1>
        <div className="button_box-right">
          <button>shop by catagroy</button>
        </div>
        <div className="image_box_right">
          <img
            src="https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Mask-Group-22.png
"
          />
        </div>
      </div>
    </div>
  );
}
