import React from "react";
import './Two_box.css'
const Two_box = () => {
  return (
    <div className="two_box_container">
        
      <div className="left_side_two_box">
      <div className="two_box_img"><img src="https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Mask-Group-15.png"/></div>
        <div className="two_box_detail">
        <p className="two_box-ptag-left">Blast Past Fast</p>
        <h1 className="two_box_heading-tag-left">
          IPhone 12 Pro <br /> It’s A Leap <br /> Year.
        </h1>
        <div className="two_box_button_left">
          <button>shop by catagroy</button>
        </div>
        </div>


      </div>


      {/*-------------right side start */}


      <div className="right_side_two_box">
      <div className="two_box_img_right"><img src="https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Mask-Group-16.png"/></div>

      <div className="two_box_detail_right">
      <p className="two_box-ptag-right">Blast Past Fast</p>
        <h1 className="two_box_heading-tag-right">
          IPhone 12 Pro <br /> It’s A Leap <br /> Year.
        </h1>
        <div className="two_box_button_right">
          <button>shop by catagroy</button>
        </div>
        </div>
        


        </div>
        { /*----right side end----- */}



    </div>
  );
};

export default Two_box;
