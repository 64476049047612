import React from 'react'
import './Footer_container.css'
const Footer_container = () => {
  return (
    <div className='Container_f'>    
        <div className='lower_contaienr'>
        <div className='para_f'>Sign Up Newsletter & Promotions!</div>
        <div className='heading_f'>Get 25% Discount<br/> <span className='heading_f_b'> On Your Next Purchase</span></div>
        <div className='input_field_f'>
        <input type='text' placeholder='Enter your email' />
    
</div>
<div className='input_field_f2'>
    <button>Get started</button>
</div>

        </div>
    </div>
  )
}

export default Footer_container
