import React, { useState } from 'react';
import './Navbar.css'; // Create a separate CSS file for styling
import { BiMenuAltRight } from 'react-icons/bi';


const Navbar = () => {
  const [menuopen, setmenuopen] = useState(false);

  const getMenustyle = (menuopen) => {
    if (document.documentElement.clientWidth <= 800) {
      return { right: menuopen ? 30 : "-100%" };
    } else {
      return {};
    }
  };

  return (
    <div className="navbar">
      <div className="left-side-nav">
       <div> <img src="https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10047.png" alt="Icon" className="icon" /></div>
       
        <div className="nav-links h-menu" style={getMenustyle(menuopen)}>
          <li>Home</li>
          <li>About</li>
          <li>Blog</li>
          <li>Shop</li>
          <li>Contact</li>
        </div>
      </div>
      <div className="right-side-nav">
        <div className="icon-container">

          <img src="./image/user_icon.png"  className="icon" />
          <span className="divider">Login</span>

          <span className="divider">|</span>
        </div>
        <div className="icon-container">
          <img src="./image/search_icon.png" alt="Search" className="icon" />
          <span className="divider">Search</span>
          <span className="divider">|</span>
          
        </div>
        <div className="icon-container">

          <img src="./image/shopping-bag_icon.png" alt="Basket" className="icon" />
          <span className="divider">Basket</span>
        </div>
      </div>
      <div className="menu-icon" onClick={() => setmenuopen(prev => !prev)}>
            <BiMenuAltRight size={30} color='black' />
      </div>
    </div>
  );
};

export default Navbar;
