import React from 'react'
import './Footer2.css'
const Footer2 = () => {
  return (
    <div className='footer_container'>
       <div className='lower_container_footer'>
           <div className='image_container'>
                <img src='https://demo.phlox.pro/shop-digital-2/wp-content/uploads/sites/274/2021/04/Group-10047.png'/>
           </div>
           <div className='Vist_link_container'>
                <div className='heading_vist'>Visit Link</div>
                   <div className='para_vist'>Shop</div>
                   <div className='para_vist'>Privacy</div>
                   <div className='para_vist'>Terms & Conditions</div>

           </div>
           <div className='Company_container'>
           <div className='heading_company'>Company</div>
                   <div className='para_company'>Home</div>
                   <div className='para_company'>About Us</div>
                   <div className='para_company'>Contact Us</div>

           </div>
           <div className='Cantact_container'>
           <div className='heading_contact'>Contact</div>
                   <div className='para_contact'>+99 (0) 101 0000 888</div>
                   <div className='para_contact'>Info@yourdomain.com</div>
           </div>

           <div className='Address_container'>
                 <div className='heading_Address'>Address</div>
                   <div className='para_Address'>Patricia Amedee 4401</div>
                   <div className='para_Address'>Waldeck Street Grapevine</div>
                   <div className='para_Address'>Nashville, Tx 76051</div>
           </div>
       </div>
       <hr className='hr_footer'></hr>

       <div className='Last_container'>
          <div className='Left_c'>© 2023 By Averta. All rights reserved.</div>
          <div className='Right_c'>Icon</div>

       </div>

    </div>
  )
}

export default Footer2
