import React from "react";
import "./Blog.css";
const Blog = () => {
  return (
    <div className="Blog_container_full">
      <h1 className="Blog_heading">Latest Posts</h1>
      <div className="Blog_container">
        <div className="Blog1_container b1_c">
          <div className="date"> April 24, 2021</div>
          <div className="para">
            GameStop to Offer
            <br /> Up to $1 Billion in
          </div>
          <hr className="hr_blog" />
          <div className="read_more">Read More</div>
        </div>

        <div className="Blog1_container b2_c">
          <div className="date"> April 24, 2021</div>
          <div className="para">
            GameStop to Offer
            <br /> Up to $1 Billion in
          </div>
          <hr className="hr_blog" />
          <div className="read_more">Read More</div>
        </div>
        <div className="Blog1_container b3_c">
          <div className="date"> April 24, 2021</div>
          <div className="para">
            GameStop to Offer
            <br /> Up to $1 Billion in
          </div>
          <hr className="hr_blog" />
          <div className="read_more">Read More</div>
        </div>
        <div className="Blog1_container b4_c">
          <div className="date"> April 24, 2021</div>
          <div className="para">
            GameStop to Offer
            <br /> Up to $1 Billion in
          </div>
          <hr className="hr_blog" />
          <div className="read_more">Read More</div>
        </div>
      </div>
    </div>
  );
};
export default Blog;
