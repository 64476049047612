import React from 'react';
import './Header.css'; // You can create a CSS file for styling

const Header = () => {
  return (
    <div className="header">
      <div className="left-side">
        <div className="icon">
          {/* You can replace 'icon' with your desired icon component or image */}
          <img src='./image/shopping-bag.png' alt="Icon" />
        </div>
        <div className="text">
          {/* Your 5-word text */}
          <span>World's Fastest Online Shopping Destination</span>
        </div>
      </div>
      <div className="right-side">
        <div className="contact-info">
          <div className="icon">
            {/* Icon for phone */}
            <img src="./image/phone-call.png" alt="Phone Icon" />
          </div>
          <div className="info">
            {/* Phone number */}
            <span>123-456-7890</span>
          </div>
        </div>
        <div className="contact-info-right">
          <div className="icon">
            {/* Icon for email */}
            <img src="./image/email.png" alt="Email Icon" />
          </div>
          <div className="info">
            {/* Email address */}
            <span>example@example.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
